import { withStyles } from '@material-ui/core/styles';
import { Box, Button, InputBase, Typography } from '@material-ui/core';

export const SearchInput = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
  },
})(InputBase);

export const MenuButton = withStyles({
  root: {
    padding: '12px 50px 12px 0px',
    textTransform: 'none',
    letterSpacing: 'normal',
    fontFamily: 'Source Sans Pro',
    alignItems: 'normal',

    '&:hover': {
      color: '#2596BE',
      backgroundColor: 'transparent',
      fontWeight: '400',
    },
    '&:hover .MuiSvgIcon-root': {
      color: '#2596BE',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      marginRight: '2px',
    },
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})(Button);

export const MenuButtonText = withStyles({
  root: {
    display: 'grid',
    fontSize: '16px',
    '&::after': {
      content: 'attr(data-text)',
      const: 'attr(data-text) / ""',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      pointerEvents: 'none',
      fontWeight: '700',
    },
  },
})(Box);

export const FooterMenuButton = withStyles({
  root: {
    '& .MuiButton-startIcon': {
      marginRight: '10px',
    },
  },
})(MenuButton);

export const TermsAndConditionsButton = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    '& .MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
})(FooterMenuButton);

export const TermsAndConditionsButtonText = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuButtonText);

export const BellIconWrapper = withStyles({
  root: {
    position: 'absolute',
    color: '#2596BE',
    top: -4,
    right: -1,
    '& .MuiSvgIcon-root': {
      width: '8px',
      height: '8px',
    },
  },
})(Box);

export const AddNewUserButton = withStyles({
  root: {
    marginLeft: 'auto',
    textTransform: 'none',
    letterSpacing: 'normal',
    fontFamily: 'Source Sans Pro',
    alignItems: 'center',
    backgroundColor: '#2596BE',
    color: '#FFFFFF',
    padding: '11px 36px',
    fontSize: '16px',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'rgba(37, 150, 190, 0.6)',
    },
  },
})(Button);

export const ExpandMoreIconWrapper = withStyles({
  root: {
    color: '#000000',
  },
})(Box);

export const PageSectionHeaderTitle = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    color: '#000000',
    fontWeight: 600,
    fontSize: '36px',
  },
})(Typography);

export const PagesContainer = withStyles({
  root: {
    width: '100%',
    marginTop: '30px',
  },
})(Box);

export const RemoveButton = withStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'none',
    color: '#BE4D25',
  },
})(Button);
