import React, { ChangeEvent, Component } from "react";
import { MyProfileProps } from "./types/myProfileProps";
import { Badge } from "../../../../styledElements";
import { Tooltip } from "@material-ui/core";
import {
  FlexColumnWrapper,
  FlexColumnWrapperMedium,
  TitleBold,
  TitleLight,
  TitleMedium,
  TextFieldStyled,
  ProfileWrapper,
  ProfileHeader,
  AvatarStyled,
  ButtonsWrapper,
  ResetButton,
  SaveButton,
  EditTextfieldsWrapper,
  InfoWrapper,
  Info,
  LocationWrapper,
  LocationIcon,
  ExpertiseWrapper,
  SkillsWrapper,
  About,
  EditButton,
  UploadButtonWrapper,
  InputFile,
  UploadButton
} from "../../styles/styledElements";
import { configJSON } from "../../../../ConsultantDashboardController.web";

export class MyProfile extends Component<MyProfileProps> {
  state = {
    editable: false,
    name: this.props.data.name,
    email: this.props.data.email,
    about: this.props.data.about,
    expertise: this.props.data.expertise,
    skills: this.props.data.skills
  };

  constructor(props: MyProfileProps) {
    super(props);

    this.setEditable = this.setEditable.bind(this);
    this.resetEditable = this.resetEditable.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveChangesHandler = this.saveChangesHandler.bind(this);
  }

  setEditable() {
    this.setState({ editable: true });
  }

  resetEditable() {
    this.setState({ ...this.props.data, editable: false });
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.name === "skills") {
      const skills = e.target.value.split(",");
      this.setState({ skills });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  saveChangesHandler() {
    this.setState({ editable: false });
  }

  render() {
    return (
      <ProfileWrapper>
        <ProfileHeader>
          <AvatarStyled />
          {this.state.editable && (
            <ButtonsWrapper>
              <ResetButton variant="outlined">
                {configJSON.removeButtonTitle}
              </ResetButton>
              <UploadButtonWrapper>
                <UploadButton>
                  {configJSON.uploadButtonTitle}
                  <InputFile type="file" />
                </UploadButton>
              </UploadButtonWrapper>
            </ButtonsWrapper>
          )}
        </ProfileHeader>
        {this.state.editable ? (
          <>
            <EditTextfieldsWrapper>
              <FlexColumnWrapperMedium>
                <TitleMedium>{configJSON.nameTitle}</TitleMedium>
                <TextFieldStyled
                  id="nameInput"
                  name="name"
                  variant="outlined"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapperMedium>

              <FlexColumnWrapperMedium>
                <TitleMedium>{configJSON.emailTitle}</TitleMedium>
                <TextFieldStyled
                  name="email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapperMedium>

              <FlexColumnWrapper>
                <TitleMedium>{configJSON.aboutTitle}</TitleMedium>
                <TextFieldStyled
                  name="about"
                  variant="outlined"
                  value={this.state.about}
                  multiline
                  minRows={6}
                  maxRows={6}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapper>

              <FlexColumnWrapper>
                <TitleMedium>{configJSON.expertiseTitle}</TitleMedium>
                <TextFieldStyled
                  name="expertise"
                  variant="outlined"
                  value={this.state.expertise}
                  multiline
                  minRows={6}
                  maxRows={6}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapper>

              <FlexColumnWrapperMedium>
                <TitleMedium>{configJSON.areasOfExpertiseTitle}</TitleMedium>
                <TextFieldStyled
                  name="skills"
                  variant="outlined"
                  value={this.state.skills.join(",")}
                  onChange={this.handleInputChange}
                />
              </FlexColumnWrapperMedium>
            </EditTextfieldsWrapper>
            <ButtonsWrapper>
              <SaveButton onClick={this.saveChangesHandler} id="save-btn">
                {configJSON.saveChangesButton}
              </SaveButton>
              <ResetButton variant="outlined" onClick={this.resetEditable}>
                {configJSON.resetButtonTitle}
              </ResetButton>
            </ButtonsWrapper>
          </>
        ) : (
          <>
            <InfoWrapper>
              <Info>
                <div>
                  <TitleBold>{this.state.name}</TitleBold>
                  <TitleLight>{this.props.data.position}</TitleLight>
                </div>
                <LocationWrapper>
                  <LocationIcon />
                  <TitleLight>{this.props.data.location}</TitleLight>
                </LocationWrapper>
                <ExpertiseWrapper>
                  <TitleBold>{configJSON.areasOfExpertiseTitle}</TitleBold>
                  <SkillsWrapper>
                    {this.state.skills.map((skill, index) => (
                      <Tooltip key={`${index}-${skill}`} title={skill}>
                        <Badge>{skill}</Badge>
                      </Tooltip>
                    ))}
                  </SkillsWrapper>
                </ExpertiseWrapper>
              </Info>
              <About>
                <FlexColumnWrapper>
                  <TitleBold>{configJSON.aboutTitle}</TitleBold>
                  <TitleLight>{this.state.about}</TitleLight>
                </FlexColumnWrapper>
                <FlexColumnWrapper>
                  <TitleBold>{configJSON.expertiseTitle}</TitleBold>
                  <TitleLight>{this.state.expertise}</TitleLight>
                </FlexColumnWrapper>
              </About>
            </InfoWrapper>
            <EditButton
              variant="outlined"
              onClick={this.setEditable}
              data-test-id="editBtn"
              id="edit-btn"
            >
              {configJSON.editProfileButtonTitle}
            </EditButton>
          </>
        )}
      </ProfileWrapper>
    );
  }
}
