import { withStyles, styled } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button
} from "@material-ui/core";
import { MenuButton } from "../../../styledElements";

export const AccordionWrapper = withStyles({
  root: {
    "& .MuiAccordion-root:before": {
      content: "none"
    },
    "& .Mui-expanded": {
      margin: 0
    }
  }
})(Box);

export const AccordionStyled = withStyles({
  root: {
    boxShadow: "none",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0
    }
  }
})(Accordion);

export const AccordionSummaryStyled = withStyles({
  root: {
    margin: 0,
    padding: 0,
    minHeight: 0,
    "& .MuiAccordionSummary-content": {
      margin: 0
    }
  }
})(AccordionSummary);

export const MenuButtonStyled = withStyles({
  root: {
    width: "100%",
    padding: "12px 0px",
    paddingLeft: "10px",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      "& .MuiButton-endIcon": {
        marginLeft: "20px"
      }
    }
  }
})(MenuButton);

export const AccordionDetailsStyled = withStyles({
  root: {
    padding: 0
  }
})(AccordionDetails);

export const AccordionDetailsWrapper = withStyles({
  root: {
    padding: "10px 0 0 10px",
    margin: "10px 0 0 10px",
    borderLeft: "1px solid #00000066",
    borderBottom: "1px solid #00000066"
  }
})(Box);

export const AccordionDetailsButtonWrapper = styled("div")({
  backgroundColor: "#ffffff",
  margin: "0 -6px -16px 10px",
  paddingLeft: "2px"
});

export const AccordionDetailsButton = withStyles({
  root: {
    textTransform: "none",
    letterSpacing: "normal",
    fontFamily: "Source Sans Pro",
    alignItems: "normal",
    fontWeight: 400,
    fontSize: "16px",

    "&:hover": {
      color: "#2596BE",
      backgroundColor: "transparent",
      fontWeight: "400"
    },
    "&:hover .MuiSvgIcon-root": {
      color: "#2596BE"
    },
    "& .MuiButton-startIcon": {
      margin: 0,
      marginRight: "2px"
    },
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
})(Button);
