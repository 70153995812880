import React from 'react';

import {
  Container,
  Box,
  // Customizable Area Start
  AppBar,
  Avatar,
  IconButton,
  // Customizable Area End
} from '@material-ui/core';

// Customizable Area Start
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  SearchInput,
  MenuButton,
  MenuButtonText,
  FooterMenuButton,
  TermsAndConditionsButton,
  TermsAndConditionsButtonText,
  AddNewUserButton,
  ExpandMoreIconWrapper,
  PageSectionHeaderTitle,
  PagesContainer,
} from './styledElements';

import { searchIcon, logoIcon } from './assets';
import './styles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import AdminConsoleController, { Props, configJSON } from './AdminConsoleController';
import { HomeIcon } from './components/icons/HomeIcon';
import { EmployeesIcon } from './components/icons/EmployeesIcon';
import { ConsultantsIcon } from './components/icons/ConsultantsIcon';
import { PowerIcon } from './components/icons/PowerIcon';
import { QuestionIcon } from './components/icons/QuestionIcon';
import { ExpandMore } from '@material-ui/icons';
import { NotificationButton } from './components/NotificationButton';
import { AddNewUserIcon } from './components/icons/AddNewUserIcon';
import { AddUserForm } from './components/AddUserForm';
import { DashboardSection } from './components/DashboardSection';
import { EmployeesSection } from './components/EmployeesSection';
import { ConsultantsSection } from './components/ConsultantsSection';

export default class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false}>
          <AppBar>
            <Box sx={webStyle.appBarWrapper}>
              <img src={logoIcon} width={86} height={43} />
              <Box sx={webStyle.searchWrapper}>
                <Box sx={webStyle.searchIcon}>
                  <img src={searchIcon} />
                </Box>
                <SearchInput placeholder={configJSON.searchPlaceholder} />
              </Box>
              <Box sx={webStyle.avatarSectionWrapper}>
                <NotificationButton gotNewNotification />
                <Box sx={webStyle.avatarWithActionWrapper}>
                  <Avatar style={{ width: '50px', height: '50px' }} />
                  <IconButton>
                    <ExpandMoreIconWrapper>
                      <ExpandMore />
                    </ExpandMoreIconWrapper>
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </AppBar>

          <Box sx={webStyle.mainWrapper}>
            <Box sx={webStyle.leftMenu}>
              <Box sx={webStyle.menuWrapper}>
                <Box sx={webStyle.upperMenuWrapper}>
                  <MenuButton
                    startIcon={<HomeIcon />}
                    style={this.state.selectedPage === 'dashboard' ? webStyle.activeButton : {}}
                    onClick={() => this.setSelectedPage('dashboard')}
                    data-test-id='dashboardBtn'
                  >
                    <MenuButtonText data-text={configJSON.dashboardButtonText}>
                      {configJSON.dashboardButtonText}
                    </MenuButtonText>
                  </MenuButton>
                  <MenuButton
                    startIcon={<EmployeesIcon />}
                    style={this.state.selectedPage === 'employees' ? webStyle.activeButton : {}}
                    onClick={() => this.setSelectedPage('employees')}
                    data-test-id='employeesBtn'
                    id='employees-btn'
                  >
                    <MenuButtonText data-text={configJSON.employeesButtonText}>
                      {configJSON.employeesButtonText}
                    </MenuButtonText>
                  </MenuButton>
                  <MenuButton
                    startIcon={<ConsultantsIcon />}
                    style={this.state.selectedPage === 'consultants' ? webStyle.activeButton : {}}
                    onClick={() => this.setSelectedPage('consultants')}
                  >
                    <MenuButtonText data-text={configJSON.consultantsButtonText}>
                      {configJSON.consultantsButtonText}
                    </MenuButtonText>
                  </MenuButton>
                </Box>
                <Box sx={webStyle.footMenuWrapper}>
                  <FooterMenuButton startIcon={<PowerIcon />}>
                    <MenuButtonText data-text={configJSON.logoutButtonText}>
                      {configJSON.logoutButtonText}
                    </MenuButtonText>
                  </FooterMenuButton>
                </Box>
              </Box>

              <Box sx={webStyle.termsAndConditionsWrapper}>
                <TermsAndConditionsButton startIcon={<QuestionIcon />}>
                  <TermsAndConditionsButtonText data-text={configJSON.termsAndConditionsButtonText}>
                    {configJSON.termsAndConditionsButtonText}
                  </TermsAndConditionsButtonText>
                </TermsAndConditionsButton>
              </Box>
            </Box>

            <Box sx={webStyle.mainSection}>
              <Box sx={webStyle.mainSectionHeader}>
                <PageSectionHeaderTitle>
                  {this.state.selectedPage === 'dashboard' && configJSON.dashboardPageTitle}
                  {this.state.selectedPage === 'employees' && configJSON.employeesPageTitle}
                  {this.state.selectedPage === 'consultants' && configJSON.consultantsPageTitle}
                </PageSectionHeaderTitle>

                <AddNewUserButton startIcon={<AddNewUserIcon />} onClick={this.handleOpenAddNewUser}>
                  {configJSON.addNewUserButtonText}
                </AddNewUserButton>

                <AddUserForm open={this.state.openAddNewUser} onClose={this.handleCloseAddNewUser} />
              </Box>

              <PagesContainer>
                {this.state.selectedPage === 'dashboard' && <DashboardSection />}
                {this.state.selectedPage === 'employees' && <EmployeesSection />}
                {this.state.selectedPage === 'consultants' && <ConsultantsSection />}
              </PagesContainer>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBarWrapper: {
    padding: '18px 40px',
    display: 'flex',
    alignItems: 'center',
  },
  searchWrapper: {
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '176px',
    gridGap: '22px',
  },
  searchIcon: {
    color: '#2596BE',
  },
  avatarSectionWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gridGap: '30px',
  },
  avatarWithActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '10px',
  },
  leftMenu: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100% - 147px)',
    maxWidth: '270px',
    bgcolor: '#FFFFFF',
    paddingTop: '147px',
    display: 'flex',
    flexFlow: 'column',
    gap: '20px',
  },
  menuWrapper: {
    height: '100%',
    padding: '0 40px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  upperMenuWrapper: {
    display: 'flex',
    flexFlow: 'column',
    gap: '20px',
  },
  footMenuWrapper: {
    display: 'flex',
    flexFlow: 'column',
    gap: '20px',
  },
  termsAndConditionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid #0000001A',
  },
  mainSection: {
    padding: '110px 40px 40px 310px',
    width: '100%',
    bgcolor: '#F1F2F5',
  },
  mainSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dashboardButtonText: {
    content: 'attr(data-text)',
  },
  activeButton: {
    fontWeight: 700,
    backgroundColor: '#2596BE1A',
    color: '#2596BE',
  },
};
// Customizable Area End
