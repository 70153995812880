import React, { Component } from "react";
import { ConsultantAppBarProps } from "./types/consultantAppBarProps";
import { AppBar, IconButton } from "@material-ui/core";
import {
  Wrapper,
  Logo,
  SearchWrapper,
  SearchIcon,
  SearchInput,
  AvatarWrapper,
  AvatarWithAction,
  AvatarStyled,
  IconWrapper
} from "./styledElements";
import { searchIcon, logoIcon } from "../../assets";
import { configJSON } from "../../ConsultantDashboardController.web";
import { NotificationButton } from "../NotificationButton";
import { ExpandMore } from "@material-ui/icons";

export class ConsultantAppBar extends Component<ConsultantAppBarProps> {
  constructor(props: ConsultantAppBarProps) {
    super(props);
  }

  render() {
    return (
      <AppBar>
        <Wrapper>
          <Logo src={logoIcon} />
          <SearchWrapper>
            <SearchIcon src={searchIcon} />
            <SearchInput placeholder={configJSON.searchPlaceholder} />
          </SearchWrapper>
          <AvatarWrapper>
            <NotificationButton gotNewNotification />
            <AvatarWithAction>
              <IconButton onClick={this.props.settingsOnClick}>
                <AvatarStyled />
              </IconButton>
              <IconButton>
                <IconWrapper>
                  <ExpandMore />
                </IconWrapper>
              </IconButton>
            </AvatarWithAction>
          </AvatarWrapper>
        </Wrapper>
      </AppBar>
    );
  }
}
