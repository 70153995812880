import React, { Component } from 'react';
import { UserTableProps } from './userTableProps';
import { Table, TableBody } from '@material-ui/core';
import { HeadCell, TableContainerStyled, TableHeadStyled, HeadRowStyled, PaginationStyled } from './userTableElements';

interface S {
  page: number;
}

export class UserTable<T> extends Component<UserTableProps<T>, S> {
  constructor(props: UserTableProps<T>) {
    super(props);
    this.state = {
      page: 1,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  rowsPerPage = this.props.rowsPerPage || 10;

  handleChangePage(event: React.ChangeEvent<unknown>, newPage: number) {
    this.setState({ page: newPage });
  }

  render() {
    return (
      <TableContainerStyled>
        <Table>
          <TableHeadStyled>
            <HeadRowStyled>
              {this.props.columnTitles.map((title, index) => (
                <HeadCell key={`${title}-${index}`}>{title}</HeadCell>
              ))}
            </HeadRowStyled>
          </TableHeadStyled>
          <TableBody>
            {this.props.bodyItems
              .slice(
                (this.state.page - 1) * this.rowsPerPage,
                (this.state.page - 1) * this.rowsPerPage + this.rowsPerPage
              )
              .map(this.props.renderBodyItems)}
          </TableBody>
        </Table>
        <PaginationStyled
          count={Math.ceil(this.props.bodyItems.length / this.rowsPerPage)}
          variant='outlined'
          shape='rounded'
          onChange={this.handleChangePage}
        />
      </TableContainerStyled>
    );
  }
}
