import React from 'react';
import { AvatarWithNameWrapper } from '../AvatarWithNameWrapper';
import { RemoveButton } from '../../styledElements';
import type { Employee } from './types/employee';
import { UserTable } from '../UserTable';
import { Cell } from '../UserTable/userTableElements';
import { TableRow } from '@material-ui/core';
import { employees } from './mockData/employees';
import { columnTitles } from './constants/columnTitles';
import { configJSON } from '../../AdminConsoleController';

export const EmployeesSection = () => {
  return (
    <UserTable<Employee>
      rowsPerPage={10}
      columnTitles={columnTitles}
      bodyItems={employees}
      renderBodyItems={({ id, name, email, company, consultantNumber, date, appontmentsAmount }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>{name}</AvatarWithNameWrapper>
          </Cell>
          <Cell>{email}</Cell>
          <Cell>{company}</Cell>
          <Cell>{consultantNumber}</Cell>
          <Cell>{date}</Cell>
          <Cell>{appontmentsAmount}</Cell>
          <Cell>
            <RemoveButton>{configJSON.removeAccessButtonText}</RemoveButton>
          </Cell>
        </TableRow>
      )}
    />
  );
};
