import React from 'react';
import { Box } from '@material-ui/core';
import {
  imgActiveEmployeesMock,
  imgApprovalRequestMock,
  imgNotificationsMock,
  imgActiveConsultantsMock,
} from './mocks/mocks';
import { withStyles } from '@material-ui/core/styles';

export const DashboardSection = () => {
  return (
    <DashboardWrapper>
      <MainSectionWrapper>
        <ActiveEmployeesImgWrapper>
          <img src={imgActiveEmployeesMock} alt='Active Employees' style={{ width: '100%' }} />
        </ActiveEmployeesImgWrapper>

        <Box sx={{ display: 'flex', gridGap: '20px' }}>
          <Box>
            <img src={imgActiveConsultantsMock} alt='Active Employees' style={{ width: '100%' }} />
          </Box>

          <ApprovalRequestImgWrapper>
            <img src={imgApprovalRequestMock} alt='Active Employees' style={{ width: '100%' }} />
          </ApprovalRequestImgWrapper>
        </Box>
      </MainSectionWrapper>

      <NotificationImgWrapper>
        <img src={imgNotificationsMock} alt='Active Employees' style={{ width: '100%' }} />
      </NotificationImgWrapper>
    </DashboardWrapper>
  );
};

const DashboardWrapper = withStyles({
  root: {
    display: 'flex',
    gap: '20px',
  },
})(Box);

const MainSectionWrapper = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    gap: '30px',
  },
})(Box);

const ActiveEmployeesImgWrapper = withStyles({})(Box);

const ApprovalRequestImgWrapper = withStyles({})(Box);

const NotificationImgWrapper = withStyles({
  root: {
    width: '300px',
  },
})(Box);
