import React from "react";
import { Container, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./styles.css";
import {
  ButtonText,
  FooterButton,
  TermsAndConditionsButton,
  TermsAndConditionsButtonText,
  PageSectionHeaderTitle,
  PagesContainer,
  ReportsMenuButton,
  RequestButton
} from "./styledElements";
import { ReportsIcon } from "./components/icons/ReportsIcon";
import { GearIcon } from "./components/icons/GearIcon";
import { PowerIcon } from "./components/icons/PowerIcon";
import { QuestionIcon } from "./components/icons/QuestionIcon";
import { AppointmentsSection } from "./components/AppointmentsSection";
import { ButtonWithDropDown } from "./components/ButtonWithDropdown";
import { RequestsSection } from "./components/RequestsSection";
import { ReportsSection } from "./components/ReportsSection";
import ConsultantDashboardController, {
  Props,
  configJSON
} from "./ConsultantDashboardController.web";
import { ConsultantAppBar } from "./components/ConsultantAppBar";
import { Settings } from "./components/Settings";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});

export default class ConsultantDashboard extends ConsultantDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false}>
          <ConsultantAppBar
            settingsOnClick={() => this.setSelectedPage("settings")}
          />
          <Box sx={webStyle.main}>
            <Box sx={webStyle.menu}>
              <Box sx={webStyle.menuContainer}>
                <Box sx={webStyle.upperWrapper}>
                  <ButtonWithDropDown
                    id="appointments-btn"
                    data-test-id="appointmentsBtn"
                    active={this.state.selectedPage === "appointments"}
                    onClick={() => this.setSelectedPage("appointments")}
                    title={configJSON.appointmentsButtonText}
                    accordionDetails={
                      <RequestButton
                        onClick={() => this.setSelectedPage("requests")}
                        style={
                          this.state.selectedPage === "requests"
                            ? webStyle.activeButton
                            : {}
                        }
                      >
                        {configJSON.appointmentsButtonNewRequests}
                      </RequestButton>
                    }
                  />

                  <ReportsMenuButton
                    id="reports-btn"
                    startIcon={<ReportsIcon />}
                    style={
                      this.state.selectedPage === "reports"
                        ? webStyle.activeButton
                        : {}
                    }
                    onClick={() => this.setSelectedPage("reports")}
                  >
                    <ButtonText data-text={configJSON.reportsButtonText}>
                      {configJSON.reportsButtonText}
                    </ButtonText>
                  </ReportsMenuButton>
                </Box>

                <Box sx={webStyle.footer}>
                  <FooterButton
                    startIcon={<GearIcon />}
                    onClick={() => this.setSelectedPage("settings")}
                    style={
                      this.state.selectedPage === "settings"
                        ? webStyle.activeButton
                        : {}
                    }
                  >
                    <ButtonText data-text={configJSON.settingsButtonText}>
                      {configJSON.settingsButtonText}
                    </ButtonText>
                  </FooterButton>
                  <FooterButton startIcon={<PowerIcon />}>
                    <ButtonText data-text={configJSON.logoutButtonText}>
                      {configJSON.logoutButtonText}
                    </ButtonText>
                  </FooterButton>
                </Box>
              </Box>

              <Box sx={webStyle.termsWrapper}>
                <TermsAndConditionsButton startIcon={<QuestionIcon />}>
                  <TermsAndConditionsButtonText
                    data-text={configJSON.termsAndConditionsButtonText}
                  >
                    {configJSON.termsAndConditionsButtonText}
                  </TermsAndConditionsButtonText>
                </TermsAndConditionsButton>
              </Box>
            </Box>

            <Box sx={webStyle.mainSectionWrapper}>
              <Box sx={webStyle.mainSectionHeader}>
                <PageSectionHeaderTitle>
                  {this.state.selectedPage === "appointments" &&
                    configJSON.appointmentsPageTitle}
                  {this.state.selectedPage === "requests" &&
                    configJSON.requestsPageTitle}
                  {this.state.selectedPage === "reports" &&
                    configJSON.reportsPageTitle}
                  {this.state.selectedPage === "settings" &&
                    configJSON.settingsPageTitle}
                </PageSectionHeaderTitle>
              </Box>

              <PagesContainer>
                {this.state.selectedPage === "appointments" && (
                  <AppointmentsSection />
                )}
                {this.state.selectedPage === "requests" && <RequestsSection />}
                {this.state.selectedPage === "reports" && <ReportsSection />}
                {this.state.selectedPage === "settings" && <Settings />}
              </PagesContainer>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  main: {
    display: "flex",
    minHeight: "100vh"
  },
  menu: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "calc(100% - 147px)",
    maxWidth: "270px",
    bgcolor: "#FFFFFF",
    paddingTop: "147px",
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  menuContainer: {
    height: "100%",
    padding: "0 40px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    gap: "20px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  upperWrapper: {
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  footer: {
    display: "flex",
    flexFlow: "column",
    gap: "20px"
  },
  termsWrapper: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #0000001A"
  },
  mainSectionWrapper: {
    padding: "110px 40px 40px 310px",
    width: "100%",
    bgcolor: "#F1F2F5"
  },
  mainSectionHeader: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  dashboardButtonText: {
    content: "attr(data-text)"
  },
  activeButton: {
    fontWeight: 700,
    backgroundColor: "#2596BE1A",
    color: "#2596BE"
  }
};
