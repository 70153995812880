import { Requests } from "../types/request";

export const requestsData = [
  [
    [
      "1-samantha@gmail.com",
      "Samantha Fox",
      "ABC",
      "08:30 12 Mar 2023",
      "25 min"
    ],
    ["ipsum ipsum"]
  ],
  [
    [
      "2-barryrichardson@email.com",
      "Barry Richardson",
      "Stone Corp.",
      "11:00 4 May 2023",
      "30 min"
    ],
    ["Ipsum shmipsum", "ipsum"]
  ],
  [
    [
      "3-leochapman@mail.com",
      "Leonard Chapman",
      "Leo Trade",
      "09:00 23 Jul 2023",
      "15 min"
    ],
    ["ipsum lorem", "Lorem"]
  ],
  [
    [
      "4-alfredtennyson@email.com",
      "Alfred Tennyson",
      "The Break",
      "14:00 16 Sep 2023",
      "60 min"
    ],
    ["ipsum ipsum", "lorem ipsum", "lorem lorem"]
  ]
];

export const requests: Requests = requestsData.map(([fields, skillsArray]) => {
  const [id, username, companyName, date, duration] = fields;
  return {
    id,
    username,
    skillsArray,
    companyName,
    date,
    duration
  };
});
