import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const BellIcon = () => {
  return ( 
    <BellSvgIcon viewBox='0 0 20 24'>
      <path d="M19.1641 19.6667H0.830811C0.688758 19.6662 0.549172 19.6295 0.425311 19.5599C0.30145 19.4904 0.197424 19.3903 0.123114 19.2693C0.0488037 19.1482 0.00667558 19.0101 0.000730273 18.8682C-0.00521503 18.7263 0.0252201 18.5852 0.089145 18.4583C1.11737 16.3859 1.6562 14.1051 1.66414 11.7917V8.83333C1.66414 6.62319 2.54212 4.50358 4.10492 2.94078C5.66772 1.37797 7.78734 0.5 9.99747 0.5C12.2076 0.5 14.3272 1.37797 15.89 2.94078C17.4528 4.50358 18.3308 6.62319 18.3308 8.83333V11.775C18.3387 14.0885 18.8776 16.3692 19.9058 18.4417C19.9737 18.5691 20.0072 18.712 20.0033 18.8563C19.9993 19.0006 19.9579 19.1414 19.8831 19.2649C19.8083 19.3884 19.7028 19.4904 19.5767 19.5608C19.4507 19.6312 19.3085 19.6677 19.1641 19.6667ZM2.12248 18H17.8725C17.0778 16.0206 16.6677 13.9079 16.6641 11.775V8.83333C16.6641 7.06522 15.9618 5.36953 14.7115 4.11929C13.4613 2.86904 11.7656 2.16667 9.99747 2.16667C8.22936 2.16667 6.53367 2.86904 5.28343 4.11929C4.03319 5.36953 3.33081 7.06522 3.33081 8.83333V11.775C3.32725 13.9079 2.91715 16.0206 2.12248 18Z" />
      <path d="M9.99772 23C8.89265 23 7.83284 22.561 7.05144 21.7796C6.27004 20.9982 5.83105 19.9384 5.83105 18.8333C5.83105 18.6123 5.91885 18.4004 6.07513 18.2441C6.23141 18.0878 6.44337 18 6.66439 18C6.8854 18 7.09736 18.0878 7.25364 18.2441C7.40992 18.4004 7.49772 18.6123 7.49772 18.8333C7.4983 19.4962 7.76208 20.1316 8.23105 20.6C8.70795 21.0525 9.3403 21.3048 9.99772 21.3048C10.6551 21.3048 11.2875 21.0525 11.7644 20.6C11.8421 20.5223 11.9343 20.4607 12.0358 20.4186C12.1374 20.3766 12.2462 20.3549 12.356 20.3549C12.4659 20.3549 12.5747 20.3766 12.6763 20.4186C12.7778 20.4607 12.87 20.5223 12.9477 20.6C13.0254 20.6777 13.087 20.7699 13.1291 20.8715C13.1711 20.973 13.1928 21.0818 13.1928 21.1917C13.1928 21.3015 13.1711 21.4104 13.1291 21.5119C13.087 21.6134 13.0254 21.7056 12.9477 21.7833C12.5601 22.17 12.1 22.4765 11.5938 22.6853C11.0876 22.894 10.5453 23.001 9.99772 23Z" />
    </BellSvgIcon>
  );
}

const BellSvgIcon = withStyles({
  root: {
    color: "#000000",
  },
})(SvgIcon);