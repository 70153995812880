import React from "react";
import { Table } from "../Table";
import { Cell, TableDataTextWrap } from "../Table/tableElements";
import { TableRow, Tooltip } from "@material-ui/core";
import { Appointment } from "./types/appointment";
import { columnTitles } from "./constants/columnTitles";
import { appointments } from "./mockData/appointments";
import { AvatarWithNameWrapper } from "../AvatarWithNameWrapper";
import { Badge, BadgeGroupWrapper, RemoveButton } from "../../styledElements";
import { configJSON } from "../../ConsultantDashboardController.web";

export const AppointmentsSection = () => {
  return (
    <Table<Appointment>
      rowsPerPage={10}
      columnTitles={columnTitles}
      bodyItems={appointments}
      renderBodyItems={({ id, name, skills, company, date, duration }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>
              <Tooltip title={name}>
                <TableDataTextWrap>{name}</TableDataTextWrap>
              </Tooltip>
            </AvatarWithNameWrapper>
          </Cell>

          <Cell>
            <Tooltip title={skills.join(", ").trim()}>
              <BadgeGroupWrapper>
                {skills.map(
                  (skill, index) =>
                    (index === 0 || index === 1) && (
                      <Badge key={`${skill}-${index}`}>{skill}</Badge>
                    )
                )}
              </BadgeGroupWrapper>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={company}>
              <TableDataTextWrap>{company}</TableDataTextWrap>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={date}>
              <TableDataTextWrap>{date}</TableDataTextWrap>
            </Tooltip>
          </Cell>

          <Cell>
            <Tooltip title={duration}>
              <TableDataTextWrap>{duration}</TableDataTextWrap>
            </Tooltip>
          </Cell>
          <Cell>
            <RemoveButton>{configJSON.cancelButtonText}</RemoveButton>
          </Cell>
        </TableRow>
      )}
    />
  );
};
