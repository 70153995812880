import React, { Component } from "react";
import { TableProps } from "./tableProps";
import { Table as MuiTable, TableBody } from "@material-ui/core";
import {
  HeadCell,
  TableContainerStyled,
  TableHeadStyled,
  HeadRowStyled,
  PaginationStyled
} from "./tableElements";

interface S {
  page: number;
}

export class Table<T> extends Component<TableProps<T>, S> {
  constructor(props: TableProps<T>) {
    super(props);
    this.state = {
      page: 1
    };
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  rowsPerPage = this.props.rowsPerPage || 10;

  handleChangePage(event: React.ChangeEvent<unknown>, newPage: number) {
    this.setState({ page: newPage });
  }

  render() {
    return (
      <TableContainerStyled>
        <MuiTable>
          <TableHeadStyled>
            <HeadRowStyled>
              {this.props.columnTitles.map((title, index) => (
                <HeadCell key={`${title}-${index}`}>{title}</HeadCell>
              ))}
            </HeadRowStyled>
          </TableHeadStyled>
          <TableBody>
            {this.props.bodyItems
              .slice(
                (this.state.page - 1) * this.rowsPerPage,
                (this.state.page - 1) * this.rowsPerPage + this.rowsPerPage
              )
              .map(this.props.renderBodyItems)}
          </TableBody>
        </MuiTable>
        <PaginationStyled
          count={Math.ceil(this.props.bodyItems.length / this.rowsPerPage)}
          variant="outlined"
          shape="rounded"
          onChange={this.handleChangePage}
        />
      </TableContainerStyled>
    );
  }
}
