import { Appointments } from "../types/appointment";

export const appointmentsData = [
  [
    [
      "1-samcook@email.com",
      "Sam Cook",
      "Sam Company",
      "11: 00 2 Feb 2023",
      "8 min"
    ],
    ["lorem ipsum"]
  ],
  [
    [
      "2-stevedwarf@email.com",
      "Steven Dwarf",
      "Dwarf Company",
      "10:00 1 May 2023",
      "25 min"
    ],
    ["lorem", "ipsum"]
  ],
  [
    [
      "3-carrywhite@email.com",
      "Carry White",
      "The Company LTD",
      "09:00 3 Jul 2023",
      "20 min"
    ],
    ["ipsum", "Lorem"]
  ],
  [
    [
      "4-adamstock@email.com",
      "Adam Stock",
      "The New Company LTD",
      "11:00 7 Jul 2023",
      "40 min"
    ],
    ["ipsum", "lorem ipsum", "lorem lorem"]
  ]
];

export const appointments: Appointments = appointmentsData.map(
  ([fields, skills]) => {
    const [id, name, company, date, duration] = fields;
    return {
      id,
      name,
      skills,
      company,
      date,
      duration
    };
  }
);
