import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export type ConsultantPage =
  | "appointments"
  | "requests"
  | "reports"
  | "settings";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedPage: ConsultantPage;
  openAddNewAppointment: boolean;
  selectedAppointmentDate: Date | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ConsultantDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPage: "appointments",
      openAddNewAppointment: false,
      selectedAppointmentDate: undefined
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setSelectedPage = (page: ConsultantPage) => {
    this.setState({ selectedPage: page });
  };

  handleOpenAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: true });
  };

  handleCloseAddNewAppointment = () => {
    this.setState({ openAddNewAppointment: false });
  };

  handleDateChange = (newDate: Date | undefined) => {
    console.log(newDate);
    this.setState({ selectedAppointmentDate: newDate });
  };
  // Customizable Area End
}
