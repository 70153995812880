import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { styled } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { configJSON } from "../../DashboardController.web";
import { CustomTableHeader } from "../CustomTableHeader";
import ImageWithName from "../ImageWithName";
import { PaginationStyled } from "../Table/tableElements";
import { IRecommendedConsultant } from "../../interfaces";

interface Props {
  consultants: IRecommendedConsultant[];
  currentPage: number;
  totalPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
}

const StyledTableRow = styled(TableRow)({
  fontFamily: "Source Sans Pro"
});

const StyledTableCellContainerDiv = styled("div")({
  display: "flex",
  fontSize: "1rem",
  fontFamily: "Source Sans Pro"
});

const PaginationContainer = styled("div")({
  display: "flex",
  justifyContent: "center"
});

const StyledExperties = styled("div")({
  fontWeight: 400,
  backgroundColor: "#2596BE1A",
  color: "#2596BE",
  fontSize: "1rem",
  margin: "0 5px",
  padding: "5px 5px",
  fontFamily: "Source Sans Pro",
  borderRadius: "5px"
});

export const MyConsultant = (props: Props) => {
  return (
    <Box>
      <TableContainer elevation={3}>
        <TableContainerBox>
          <Table>
            <CustomTableHeader
              headers={configJSON.employeeConsultantTableHeaderArr}
            />
            <TableBody>
              {props.consultants.map((consultant: IRecommendedConsultant) => (
                <MyConsultantTableBody
                  consultant={consultant}
                  key={consultant.id}
                />
              ))}
            </TableBody>
          </Table>
          <PaginationContainer>
            <PaginationStyled
              count={props.totalPage}
              variant="outlined"
              shape="rounded"
              page={props.currentPage}
              onChange={props.handleChange}
            />
          </PaginationContainer>
        </TableContainerBox>
      </TableContainer>
    </Box>
  );
};

const MyConsultantTableBody = (props: {
  consultant: IRecommendedConsultant;
}) => (
  <StyledTableRow>
    <TableCell>
      <StyledTableCellContainerDiv>
        <ImageWithName
          name={`${props.consultant.attributes.first_name} ${
            props.consultant.attributes.last_name
          }`}
          imgUrl={props.consultant.attributes.profile.data.attributes.photo}
        />
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.profile.data.attributes
          .area_of_expertise &&
          props.consultant.attributes.profile.data.attributes.area_of_expertise.map(
            (experties: string) => (
              <StyledExperties>{experties}</StyledExperties>
            )
          )}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.profile.data &&
          props.consultant.attributes.profile.data.attributes.company}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.no_of_employees}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        {props.consultant.attributes.no_of_employees}
      </StyledTableCellContainerDiv>
    </TableCell>
    <TableCell>
      <StyledTableCellContainerDiv>
        <ScheduleAppointmentWrapper>
          Reschedule Appointment
        </ScheduleAppointmentWrapper>
      </StyledTableCellContainerDiv>
    </TableCell>
  </StyledTableRow>
);

const TableContainer = withStyles({
  root: {
    padding: "2em 1.5rem",
    marginBottom: "2rem"
  }
})(Paper);

const TableContainerBox = withStyles({
  root: {
    marginTop: "1rem",
    fontFamily: "Source Sans Pro"
  }
})(Box);

const ScheduleAppointmentWrapper = withStyles({
  root: {
    color: "#2596BE",
    cursor: "pointer",
    fontFamily: "Source Sans Pro"
  }
})(Box);
