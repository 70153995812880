import React from "react";
import Box from "@material-ui/core/Box";
import { mockGraph } from "./mocks/mocks";
import { withStyles } from "@material-ui/core/styles";
import RecommendedConsultant from "../RecommendedConsultant";
import { UpComingWebniar } from "../UpComingWebniar";
import { IRecommendedConsultant } from "../../interfaces";
import { styled } from "@material-ui/core";

interface Props {
  recommended: IRecommendedConsultant[];
}

const StyledImage = styled("img")({
  width: "100%"
});

export const Dashboard = (props: Props) => {
  return (
    <Box>
      <RecommendedConsultant consultants={props.recommended} />
      <DashboardWrapper>
        <MainSectionWrapper>
          <Box>
            <StyledImage src={mockGraph} alt="Graph" />
          </Box>
        </MainSectionWrapper>
        <NotificationWrapper>
          <UpComingWebniar />
        </NotificationWrapper>
      </DashboardWrapper>
    </Box>
  );
};

const DashboardWrapper = withStyles({
  root: {
    display: "flex",
    gap: "20px"
  }
})(Box);

const MainSectionWrapper = withStyles({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: "30px"
  }
})(Box);

const NotificationWrapper = withStyles({
  root: {
    width: "400px"
  }
})(Box);
