import React, { ChangeEvent, Component } from "react";
import {
  SecurityTextfieldsWrapper,
  SecurityWrapper,
  ButtonsWrapper,
  ResetButton,
  SaveButton,
  FlexColumnWrapperMedium,
  TitleMedium,
  TextFieldStyled,
  FlexColumnWrapperMediumWithErrorSpace,
  TextFieldErrorTitle
} from "../../styles/styledElements";
import { SecurityProps } from "./types/securityProps";
import { configJSON } from "../../../../ConsultantDashboardController.web";

export class Security extends Component<SecurityProps> {
  state = {
    old: "",
    new: "",
    confirm: "",
    error: false
  };

  constructor(props: SecurityProps) {
    super(props);

    this.resetFields = this.resetFields.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
  }

  resetFields() {
    this.setState({
      old: "",
      new: "",
      confirm: "",
      error: false
    });
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  saveHandler() {
    if (this.state.new !== this.state.confirm) {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 3000);
    } else {
      this.resetFields();
    }
  }

  render() {
    return (
      <SecurityWrapper>
        <SecurityTextfieldsWrapper>
          <FlexColumnWrapperMedium>
            <TitleMedium>{configJSON.oldPasswordTitle}</TitleMedium>
            <TextFieldStyled
              name="old"
              variant="outlined"
              placeholder={configJSON.oldPasswordPlaceholder}
              type="password"
              value={this.state.old}
              onChange={this.handleInputChange}
            />
          </FlexColumnWrapperMedium>

          <FlexColumnWrapperMedium>
            <TitleMedium>{configJSON.newPassworsTitle}</TitleMedium>
            <TextFieldStyled
              name="new"
              variant="outlined"
              placeholder={configJSON.newPasswordPlaceholder}
              type="password"
              value={this.state.new}
              onChange={this.handleInputChange}
              data-test-id="newPswdInput"
              id="newPswd"
            />
          </FlexColumnWrapperMedium>

          <FlexColumnWrapperMediumWithErrorSpace>
            <TitleMedium>{configJSON.confirmPasswordTitle}</TitleMedium>
            <TextFieldStyled
              name="confirm"
              variant="outlined"
              placeholder={configJSON.confirmPasswordPlaceholder}
              type="password"
              value={this.state.confirm}
              onChange={this.handleInputChange}
              error={this.state.error}
              data-test-id="confirmPswdInput"
              id="confirmPswdInput"
            />
            {this.state.error && (
              <TextFieldErrorTitle>Passwords do not match</TextFieldErrorTitle>
            )}
          </FlexColumnWrapperMediumWithErrorSpace>
        </SecurityTextfieldsWrapper>

        <ButtonsWrapper>
          <SaveButton onClick={this.saveHandler} id="save-btn">
            {configJSON.saveChangesButton}
          </SaveButton>
          <ResetButton variant="outlined" onClick={this.resetFields}>
            {configJSON.resetButtonTitle}
          </ResetButton>
        </ButtonsWrapper>
      </SecurityWrapper>
    );
  }
}
