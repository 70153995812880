import React from "react";
import { styled } from "@material-ui/core";
import { dummyImage } from "../assets";

const StyledDiv = styled("div")({
  fontSize: "1rem",
  marginLeft: "5px",
  lineHeight: 1.7,
  fontFamily: "Source Sans Pro"
});

const StyledImage = styled("img")({
  width: "25px",
  height: "25px",
  borderRadius: "50%"
});

const ImageWithName = ({
  imgUrl,
  name
}: {
  imgUrl?: string | null;
  name: string | null;
}) => {
  return (
    <>
      <StyledImage
        src={imgUrl ? imgUrl : dummyImage}
        alt=""
        style={{ width: "25px", height: "25px", borderRadius: "50%" }}
      />
      <StyledDiv>{name}</StyledDiv>
    </>
  );
};

export default ImageWithName;
