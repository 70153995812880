import React from "react";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { styled } from "@material-ui/core";
import { configJSON } from "../../DashboardController.web";

const StyledContainer = styled(Box)({
  fontFamily: "Source Sans Pro"
});

const StyledInputContainer = styled(Box)({
  display: "flex",
  gap: "5px"
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "12px 25px"
  }
});

export const SelectTime = ({ label }: { label: string }) => {
  return (
    <StyledContainer>
      <label htmlFor="" data-testid="label">
        {label}
      </label>
      <StyledInputContainer>
        <StyledSelect variant="outlined">
          {configJSON.hours.map((hour: number) => (
            <MenuItem value={hour} key={hour} data-testid="hour">
              {hour}
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledSelect variant="outlined">
          {configJSON.minutes.map((min: number) => (
            <MenuItem value={min} key={min} data-testid="min">
              {min}
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledSelect variant="outlined" value="am">
          <MenuItem value="am">AM</MenuItem>
          <MenuItem value="pm">PM</MenuItem>
        </StyledSelect>
      </StyledInputContainer>
    </StyledContainer>
  );
};
