import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

export const EmployeesIcon = () => {
  return (
    <EmployeesSvgIcon style={{color: 'inherit'}}>
      <path d="M16 20V17.6667C16 16.429 15.6049 15.242 14.9017 14.3668C14.1984 13.4917 13.2446 13 12.25 13H4.75C3.75544 13 2.80161 13.4917 2.09835 14.3668C1.39509 15.242 1 16.429 1 17.6667V20M20 20V17.615C19.9996 16.5581 19.8029 15.5314 19.4409 14.6961C19.0789 13.8608 18.5721 13.2642 18 13M15 1C15.5721 1.22741 16.0792 1.74395 16.4413 2.46819C16.8034 3.19243 17 4.08318 17 5C17 5.91682 16.8034 6.80757 16.4413 7.53181C16.0792 8.25605 15.5721 8.77259 15 9M12 5C12 7.20914 10.2091 9 8 9C5.79086 9 4 7.20914 4 5C4 2.79086 5.79086 1 8 1C10.2091 1 12 2.79086 12 5Z" />
    </EmployeesSvgIcon>
  );
}

const EmployeesSvgIcon = withStyles({
  root: {
    fill: 'none',
    stroke: 'currentColor'
  },
})(SvgIcon);