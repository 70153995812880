import { Employees } from '../types/employee';

export const employees: Employees = [
  ['1-johnbristol@email.com', 'John Bristol', 'johnbristol@email.com', 'Some Company', '1', '23 Jan 2023', '2'],
  ['2-camillekostek@email.com', 'Camille Kostek', 'camillekostek@email.com', 'Any Company', '5', '12 May 2023', '4'],
  ['3-stevecarell@email.com', 'Steve Carell', 'stevecarell@email.com', 'The Company LTD', '2', '6 Jul 2023', '3'],
  ['4-testuser@email.com', 'Test User', 'testuser@email.com', 'Hello There Company', '6', '16 Jun 2023', '8'],
  ['5-billcrosby@email.com', 'Bill Crosby', 'billcrosby@email.com', 'United Drummers', '2', '10 Sep 2023', '10'],
  ['6-robertglasper@email.com', 'Robert Glasper', 'robertglasper@email.com', 'Swift Stone', '1', '03 Feb 2023', '12'],
  ['7-stevecarell@email.com', 'Steve Carell', 'stevecarell@email.com', 'The Company LTD', '2', '6 Jul 2023', '3'],
  ['8-camillekostek@email.com', 'Camille Kostek', 'camillekostek@email.com', 'Any Company', '5', '12 May 2023', '4'],
  ['9-billcrosby@email.com', 'Bill Crosby', 'billcrosby@email.com', 'United Drummers', '2', '10 Sep 2023', '10'],
  ['10-johnbristol@email.com', 'John Bristol', 'johnbristol@email.com', 'Some Company', '1', '23 Jan 2023', '2'],
  ['11-testuser@email.com', 'Test User', 'testuser@email.com', 'Hello There Company', '6', '16 Jun 2023', '8'],
  ['12-camillekostek@email.com', 'Camille Kostek', 'camillekostek@email.com', 'Any Company', '5', '12 May 2023', '4']
].map(
  ([id, name, email, company, consultantNumber, date, appontmentsAmount]) => 
    ({id, name, email, company, consultantNumber, date, appontmentsAmount})
)