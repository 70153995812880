import React from "react";
import { IconButton, Box } from "@material-ui/core";
import { NotificationIconWrapper } from "../../styledElements";
import { FiberManualRecord } from "@material-ui/icons";
import NotificationIcon from "../icons/NotificationIcon";
import { NotificationButtonProps } from "./notificationButtonProps";

export const NotificationButton = ({
  gotNewNotification = false
}: NotificationButtonProps) => {
  return (
    <IconButton>
      <Box
        sx={{
          position: "relative"
        }}
      >
        {gotNewNotification && (
          <NotificationIconWrapper>
            <FiberManualRecord />
          </NotificationIconWrapper>
        )}

        <NotificationIcon />
      </Box>
    </IconButton>
  );
};
