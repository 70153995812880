import React from "react";
import { withStyles, styled } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { AddAppointmentFormProps } from "./addAppointmentFormProps";
import { configJSON } from "../../DashboardController.web";
import { Close } from "@material-ui/icons";
import { AddAppointmentButton } from "../../styledElements";
import { DayPicker } from "react-day-picker";
import { SelectTime } from "./SelectTime";
import {IConsultant} from "../../interfaces"

const StyledDatePicker = styled(DayPicker)({
  "& .rdp-day_selected:not([disabled])": {
    fontWeight: "bold",
    borderRadius: "21px",
    background: "#2596BE",
    color: "#ffff"
  },

  "& .rdp-months": {
    display: "block"
  },

  "& .rdp-table": {
    minWidth: "100%"
  }
});

const StyledInputWapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& label": {
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "Source Sans Pro"
  },
  gap: "4px"
});

const StyledTextField = styled(TextField)({
  "& fieldset": {
    border: "1px solid #E0E0E0 !important"
  }
});

const StyledSelectInputWrapper = styled(Box)({
  width: "200px",
  marginLeft: "32px"
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-input": {
    padding: "12px 14px"
  }
});

const StyledSelectTime = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Source Sans Pro"
})

export const AddAppointmentForm = ({
  open,
  onClose,
  selectedDate,
  handleDateChange,
  listOfConsultant,
  selectedConsultant,
  handleSelectConsultant
}: AddAppointmentFormProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogWrapper>
        <DialogTitleWrapper>
          <AddAppointmentFormTitle>
            {configJSON.addAppointmentFormTitle}
          </AddAppointmentFormTitle>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </DialogTitleWrapper>
        <StyledSelectInputWrapper>
          <FormControl fullWidth>
            <StyledSelect variant="outlined" fullWidth value={selectedConsultant}  onChange={handleSelectConsultant}>
              <MenuItem disabled value="">
                <em>Select Consultant</em>
              </MenuItem>
              {listOfConsultant.map((consultant:IConsultant)=>(
                <StyledMenuItem value= {consultant.id} key ={consultant.id}>{`${consultant.first_name} ${consultant.last_name}`}</StyledMenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </StyledSelectInputWrapper>

        <DialogContentWrapper>
          <StyledDatePicker
            mode="single"
            selected={selectedDate}
            onSelect={newDate => handleDateChange(newDate)}
            // footer={footer}
            disabled={[
              new Date(
                "Tue Apr 11 2023 00:00:00 GMT+0530 (India Standard Time)"
              )
            ]}
            fromMonth={new Date()}
          />

          <StyledSelectTime>
            <SelectTime label="Start Time" />
            <SelectTime label="End Time" />
          </StyledSelectTime>

          <StyledInputWapper>
            <label htmlFor="">Subject</label>
            <StyledTextField variant="outlined" placeholder="Enter Subject" />
          </StyledInputWapper>
          <StyledInputWapper>
            <label htmlFor="">Message</label>
            <StyledTextField
              variant="outlined"
              multiline
              minRows={3}
              placeholder="Enter Message"
            />
          </StyledInputWapper>
          <UserInputWrapper>
            <EmailInputWrapper>
              <EmailInput placeholder="example@mail.com" />
            </EmailInputWrapper>
            <SendInviteButton>Send Invite</SendInviteButton>
          </UserInputWrapper>
        </DialogContentWrapper>
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = withStyles({
  root: {
    width: "560px",
    backgroundColor: "#FFFFFF"
  }
})(Box);

const DialogTitleWrapper = withStyles({
  root: {
    padding: "24px 32px 15px 32px"
  }
})(DialogTitle);

const DialogContentWrapper = withStyles({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    gap: "12px",
    marginBottom: "1rem"
  }
})(DialogContent);

const AddAppointmentFormTitle = withStyles({
  root: {
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    fontSize: "20px",
    color: "#000000"
  }
})(Typography);

const CloseButton = withStyles({
  root: {
    top: 8,
    right: 8,
    position: "absolute"
  }
})(IconButton);

const EmailInput = withStyles({
  root: {
    padding: "0px 12px",
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    flexGrow: 1
  }
})(InputBase);

const EmailInputWrapper = withStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    paddingRight: "12px",
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px",
    "& .MuiInput-underline:before": {
      border: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    "& .MuiSelect-select:focus": {
      background: "#FFFFFF"
    }
  }
})(Box);

const UserInputWrapper = withStyles({
  root: {
    display: "flex",
    gap: "14px"
  }
})(Box);

const SendInviteButton = withStyles({
  root: {
    fontWeight: 600
  }
})(AddAppointmentButton);
