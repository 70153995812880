import React from 'react';
import { UserTable } from '../UserTable';
import { Cell } from '../UserTable/userTableElements';
import { TableRow } from '@material-ui/core';
import type { Consultant } from './types/consultant';
import { columnTitles } from './constants/columnTitles';
import { consultants } from './mockData/consultants';
import { AvatarWithNameWrapper } from '../AvatarWithNameWrapper';
import { RemoveButton } from '../../styledElements';
import { configJSON } from '../../AdminConsoleController';

export const ConsultantsSection = () => {
  return (
    <UserTable<Consultant>
      rowsPerPage={10}
      columnTitles={columnTitles}
      bodyItems={consultants}
      renderBodyItems={({ id, name, email, company, consultantNumber, date, appontmentsAmount }) => (
        <TableRow key={id}>
          <Cell>
            <AvatarWithNameWrapper>{name}</AvatarWithNameWrapper>
          </Cell>
          <Cell>{email}</Cell>
          <Cell>{company}</Cell>
          <Cell>{consultantNumber}</Cell>
          <Cell>{date}</Cell>
          <Cell>{appontmentsAmount}</Cell>
          <Cell>
            <RemoveButton>{configJSON.removeAccessButtonText}</RemoveButton>
          </Cell>
        </TableRow>
      )}
    />
  );
};
