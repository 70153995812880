import React from 'react';
import {
  IconButton,
  Box
} from "@material-ui/core";
import { BellIconWrapper } from "../../styledElements";
import { FiberManualRecord } from '@material-ui/icons';
import { BellIcon } from "../icons/BellIcon";
import { NotificationButtonProps } from "./notificationButtonProps";


export const NotificationButton = ({ gotNewNotification = false }: NotificationButtonProps) => {
  return (
    <IconButton>
      <Box sx={{
        position: 'relative'
      }}>
        {
          gotNewNotification &&
          <BellIconWrapper>
            <FiberManualRecord />
          </BellIconWrapper>
        }

        <BellIcon />
      </Box>
    </IconButton>
  );
}