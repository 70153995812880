Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.validationApiContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.logoutText = "Log out";
exports.dashboardText = "Dashboard";
exports.settingText = "Settings";
exports.appointmentText = "Appointments";
exports.consultantText = "My Consultants";
exports.termAndConditionText = "Terms & Conditions";
exports.searchInputPlaceholder = "Search for Consultant";
exports.dashboardPageTitle = "Dashboard";
exports.appointmentPageTitle = "Appointments";
exports.consultantPageTitle = "My Consultants";
exports.addNewAppointmentLabel = "Add New Appointment";
exports.employeeConsultantTableHeaderArr = ["Consultant Name","Areas of Expertise","Company", "No. of Employee", "No. of Appointments", "" ];
exports.employeeAppointmentTableHeaderArr = ["Consultant Name","Areas of Expertise","Company", "Appointment Date & Time", "Duration", "Action" ];
exports.addAppointmentFormTitle = "Add New Appointment";
exports.addAppointmentFormSubTitle = "Lorem ipsum is a dummy text.";
exports.minutes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
exports.hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
exports.searchPlaceholder = "Search for people";
exports.appointmentsButtonText = "Appointments";
exports.appointmentsButonAddNew = "Add New";
exports.appointmentsButtonNewRequests = "New Requests";
exports.reportsButtonText = "Reports";
exports.appointmentsPageTitle = "Appointments";
exports.requestsPageTitle = "Appointments Requests";
exports.reportsPageTitle = "Reports";
exports.settingsPageTitle = "Settings";
exports.settingsButtonText = 'Settings';
exports.logoutButtonText = 'Log out';
exports.termsAndConditionsButtonText = 'Terms & Conditions';
exports.addNewAppointmentButtonText = 'Add New Appointment';
exports.appointmentsTableColumnName = 'Employee Name';
exports.appointmentsTableColumnSkills = 'Skills';
exports.appointmentsTableColumnCompany = 'Company';
exports.appointmentsTableColumnDate = 'Appointment Date & Time';
exports.appointmentsTableColumnDuration = 'Duration';
exports.appointmentsTableColumnAction = 'Action';
exports.cancelButtonText = 'Cancel';
exports.acceptButtonText = 'Accept';
exports.rejectButtonText = 'Reject';
exports.loginPath = "/login";
exports.signUpPath = "/SignUp";
exports.employeeDashboardPath = "/EmployeeDashboard";
exports.consultantDashboardPath = "/";
exports.adminDashboardPath = "/adminconsole";
exports.apiMethodTypePost= "POST";
exports.apiMethodTypeGet= "GET";
exports.apiMethodTypePut= "put";
exports.perPageCount = 10
exports.cancelAppointmentFormTitle = "Are you sure?"
exports.cancelAppointmentFormSubTitle = "Are you sure you want to cancel the appointment.";
exports.LogoutFormSubTitle = "Are you sure you want to logout from your dashboard.";
exports.profileTabTitle = 'My Profile';
exports.securityTabTitle = 'Security';
exports.removeButtonTitle = 'Remove';
exports.uploadButtonTitle = 'Upload';
exports.nameTitle = 'Full name';
exports.emailTitle = 'Email';
exports.aboutTitle = 'About Me';
exports.expertiseTitle = 'Expertise | Services Offered';
exports.areasOfExpertiseTitle = 'Areas of Expertise';
exports.saveChangesButton = 'Save changes';
exports.resetButtonTitle = 'Reset';
exports.editProfileButtonTitle = 'Edit My Profile';
exports.oldPasswordTitle = 'Old Password';
exports.oldPasswordPlaceholder = 'Enter old password';
exports.newPassworsTitle = 'New Password';
exports.newPasswordPlaceholder = 'Enter new password';
exports.confirmPasswordTitle = 'Confirm Password';
exports.confirmPasswordPlaceholder = 'Again enter password';
// Customizable Area End